/**@jsx jsx */
import { Box, Flex, Heading, Text, Button, jsx } from 'theme-ui'
import C1XHero from './Hero'
import TimelineV1 from './TimelineV1'

import { Fragment, useEffect, useState } from 'react'
import Modal from '../Generic/Modal'
import CTA from './CTA'
import CloseIcon from '~/assets/images/icons/close.svg'
import ShowcaseCarousel from './ShowcaseCarousel'
import HeightComponent from './HeightComponent/HeightComponent'
import KeyFeatures from './KeyFeatures'
import StudioShots from './StudioShots'
import TermsPopup from './Terms/TermsPopup'
import RichTextRenderer from '../Content/RichText'

import Logo from '~/assets/images/logos/logo-small.svg'
import { Link } from 'gatsby'
import useDebounce from '~/hooks/utils/use-debounce'

const Super73C1X = ({ page }) => {
  const { hero, sections } = page
  // Floating button
  const {
    buttonText,
    buttonLink,
    buttonBackgroundColor = '#000000',
    buttonTextColor = '#FFFFFF'
  } = hero

  // Sections
  const timeline = sections.find(section => section.slug === 'timeline')
  const showcaseCarousel = sections.find(
    section => section.slug === 'showcase-carousel'
  )
  const sizeComparison = sections.find(
    section => section.slug === 'size-comparison'
  )
  const keyFeatures = sections.find(section => section.slug === 'key-features')
  const studioShots = sections.find(section => section.slug === 'studio-shots')
  const comparisonImages = sizeComparison.images.slice(1)
  const bottomBanner = sections.find(
    section => section.slug === 'c1x-bottom-banner'
  )
  const disclaimer = sections.find(section => section.slug === 'c1x-disclaimer')

  // Index of timeline section to display in TimelineModal
  const [activeInfo, setActiveInfo] = useState(null)

  const [showVideo, setShowVideo] = useState(false)

  const [showFloatingButton, setShowFloatingButton] = useState(false)

  const [showTC, setShowTC] = useState(false)

  // Disable scroll when modal active
  useEffect(() => {
    if (activeInfo || showTC) {
      document.querySelector('main').classList.add('no-scroll')
      document.body.classList.add('no-scroll')
      document.documentElement.classList.add('no-scroll')
    } else {
      document.querySelector('main').classList.remove('no-scroll')
      document.body.classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll')
    }
  }, [activeInfo, showTC])

  const [scrollAmount, setScrollAmount] = useState(0)
  const setScrollDesktop = () => {
    setScrollAmount(document.querySelector('main').scrollTop)
  }
  const setScrollMobile = () => {
    if (typeof window !== 'undefined') {
      setScrollAmount(window.scrollY)
    }
  }
  useEffect(() => {
    document
      .querySelector('main')
      .addEventListener('scroll', setScrollDesktop, { passive: true })
    document.addEventListener('scroll', setScrollMobile, { passive: true })
    return () => {
      document
        .querySelector('main')
        .removeEventListener('scroll', setScrollDesktop, { passive: true })
      document.removeEventListener('scroll', setScrollMobile, { passive: true })
    }
  }, [])

  const debouncedScroll = useDebounce(scrollAmount, 100)

  useEffect(() => {
    if (typeof debouncedScroll === 'number' && typeof window !== 'undefined') {
      if (debouncedScroll > window.innerHeight) {
        setShowFloatingButton(true)
      } else {
        setShowFloatingButton(false)
      }
    }
  }, [debouncedScroll])

  return (
    <Fragment>
      {showTC && (
        <Modal closeModal={() => setShowTC(false)}>
          <TermsPopup close={() => setShowTC(false)} link={buttonLink} />
        </Modal>
      )}
      {activeInfo !== null && (
        <Modal closeModal={() => setActiveInfo(null)}>
          <Box
            sx={{
              backgroundColor: 'black',
              color: 'white',
              position: 'relative',
              p: '16px 45px 16px 16px',
              width: '310px',
              fontSize: '1.5rem',
              animation: 'fadeInUp 0.25s ease'
            }}
          >
            <Button
              onClick={() => setActiveInfo(null)}
              sx={{ p: 2, position: 'absolute', top: '0px', right: '0px' }}
            >
              <CloseIcon
                sx={{
                  transform: 'rotate(45deg)',
                  width: '100%',
                  height: 'auto',
                  path: { fill: 'white' }
                }}
              />
            </Button>
            <Text
              sx={{ p: { m: 0 } }}
              dangerouslySetInnerHTML={{
                __html:
                  timeline.media[activeInfo].description?.childMarkdownRemark
                    ?.html
              }}
            />
          </Box>
        </Modal>
      )}
      {buttonText && buttonLink && (
        <Flex
          sx={{
            position: 'fixed',
            backgroundColor: 'black',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            top: 0,
            left: 0,
            padding: ['10px 8px', null, '10px 20px'],
            gap: '15px',
            zIndex: 2,
            transform: showFloatingButton
              ? 'translate(0, 0)'
              : 'translate(0,-100%)',
            transition: '0.55s ease',
            button: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: 'unset',
              padding: ['1px 15px 0', '1px 36px 0'],
              backgroundColor: 'transparent !important',
              color: 'white !important',
              border: '2px solid white',
              width: '100%',
              maxWidth: '228px',
              height: ['32px', '44px'],
              fontSize: ['1.4rem', '1.5rem']
            }
          }}
        >
          <Link aria-label="Home" to="/" sx={{ display: 'flex' }}>
            <Logo
              sx={{
                width: ['140px', null, '170px'],
                height: 'auto',
                flexShrink: 0
              }}
            />
          </Link>
          <CTA
            location="c1x-cta-nav"
            onClick={() => setShowTC(true)}
            buttonText={buttonText}
            buttonBackgroundColor={buttonBackgroundColor}
            buttonLink={buttonLink}
            buttonTextColor={buttonTextColor}
          />
        </Flex>
      )}

      {showVideo && (
        <Modal closeModal={() => setShowVideo(false)}>
          <video
            playsInline
            autoPlay
            controls
            sx={{
              width: ['90vw', '60vw'],
              height: 'auto',
              zIndex: 1,
              animation: 'fadeInUp 0.5s ease'
            }}
          >
            <source
              src={hero.image.file.url}
              type={hero.image.file.contentType}
            />
          </video>
        </Modal>
      )}

      <Box sx={{ 'h1,h2': { letterSpacing: '0.07em' } }}>
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          {hero && (
            <C1XHero
              setShowVideo={setShowVideo}
              hero={hero}
              onClick={() => setShowTC(true)}
            />
          )}
          {timeline && (
            <TimelineV1
              activeInfo={activeInfo}
              setActiveInfo={setActiveInfo}
              timeline={timeline}
              showTC={setShowTC}
            />
          )}
        </Box>
        {showcaseCarousel && <ShowcaseCarousel section={showcaseCarousel} />}
        <HeightComponent
          comparisonImages={comparisonImages}
          heading={sizeComparison.title}
          c1x={sizeComparison.images[0].gatsbyImageData?.images?.fallback?.src}
        />
        {keyFeatures && <KeyFeatures section={keyFeatures} />}
        {studioShots && <StudioShots section={studioShots} />}
        {bottomBanner && (
          <button
            onClick={() => {
              if (typeof window !== 'undefined' && window.analytics) {
                window.analytics.track('C1X CTA Clicked', {
                  label: 'c1x-cta-bottom'
                })
              }
              setShowTC(true)
            }}
            sx={{
              display: 'block',
              width: '100%',
              backgroundColor: 'black',
              color: 'white',
              textAlign: 'center',
              py: '25px',
              mt: '50px',
              px: 0,
              border: 'none',
              cursor: 'pointer'
            }}
          >
            <Heading
              sx={{
                fontSize: ['1.4rem', null, null, '3.4rem'],
                letterSpacing: ['0.05em', '0.1em'],
                lineHeight: '100%'
              }}
            >
              {bottomBanner.title}
            </Heading>
          </button>
        )}
        {disclaimer && (
          <Box
            sx={{
              maxWidth: '1100px',
              m: ['36px auto 71px', null, '71px auto'],
              padding: '0 20px'
            }}
          >
            <RichTextRenderer richText={disclaimer.copy} />
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

export default Super73C1X
