import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import React from 'react'
import { graphql } from 'gatsby'
import Super73C1X from '~/components/Super73C1X'

const Super73C1XPage = ({ data }) => {
  const { page } = data
  if (page) {
    return (
      <Layout showBreadcrumbs={false}>
        <Metadata
          title={page.metaTitle || page.title}
          description={
            page.metaDescription?.metaDescription ||
            `Super73's ${page.title} page default description`
          }
        />
        <Super73C1X page={page} />
      </Layout>
    )
  }
  return <Layout></Layout>
}

export default Super73C1XPage

export const query = graphql`
  query C1XPage($locale: String) {
    page: contentfulPage(slug: { eq: "c1x" }, node_locale: { eq: $locale }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
