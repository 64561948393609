/**@jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, jsx } from 'theme-ui'
import ImageHeading from '../Generic/ImageHero/ImageHeading'
import CTA from './CTA'
const C1XHero = ({ hero, setShowVideo, onClick }) => {
  const {
    image,
    heading,
    imageHeading,
    subheading,
    buttonText,
    buttonLink,
    buttonBackgroundColor = '#000000',
    buttonTextColor = '#FFFFFF'
  } = hero
  const fullScreen = true

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          bottom: '67px',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          width: ['100%', null, null, 'auto'],
          px: ['2rem', null, null, '3rem'],
          svg: {
            maxWidth: '100%',
            height: 'auto'
          },
          '.imageHeading': {
            mb: '5rem'
          }
        }}
      >
        {subheading && subheading.subheading && (
          <h2
            sx={{
              fontWeight: 'medium',
              color: 'white',
              fontSize: '1.5rem',
              fontFamily: 'secondary',
              letterSpacing: '0.2em !important',
              margin: '0 0 5px'
            }}
          >
            {subheading.subheading}
          </h2>
        )}
        {heading && heading.heading && (
          <h1
            sx={{
              color: 'white',
              fontSize: ['2.8rem', '3.5rem', null, '5rem'],
              letterSpacing: '0.4em !important',
              fontWeight: 'medium',
              fontFamily: 'secondary',
              m: ['0 0 25px', null, null, '0 0 49px']
            }}
          >
            {heading.heading}
          </h1>
        )}
        {imageHeading && (
          <ImageHeading
            svg={imageHeading.svg}
            fluid={imageHeading.fluid}
            file={imageHeading.file}
            alt={imageHeading.file.filename}
          />
        )}
        {buttonText && buttonLink && (
          <Box>
            <CTA
              location="c1x-cta-hero"
              onClick={onClick}
              buttonText={buttonText}
              buttonBackgroundColor={buttonBackgroundColor}
              buttonLink={buttonLink}
              buttonTextColor={buttonTextColor}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%'
          // ':after': {
          //   content: ['""', null, null, 'unset'],
          //   width: '100%',
          //   height: '100%',
          //   display: 'block',
          //   position: 'absolute',
          //   top: 0,
          //   left: 0,
          //   background: 'linear-gradient(rgba(0,0,0,0) 20%, rgba(0,0,0,1) 90%)'
          // }
        }}
      >
        {image.file.contentType.split('/')[0] !== 'video' ? (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image?.description ?? image?.title}
            sx={{ height: '100%', minHeight: '300px' }}
          />
        ) : fullScreen ? (
          <video
            // onClick={() => setShowVideo(true)}
            muted
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
              // cursor: 'pointer'
            }}
            loop
            playsInline
            autoPlay
          >
            <source src={image.file.url} type={image.file.contentType} />
          </video>
        ) : (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              pb: ['75.85%', null, '50%']
            }}
          >
            <video
              onClick={() => setShowVideo(true)}
              muted
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer'
              }}
              loop
              playsInline
              autoPlay
            >
              <source src={image.file.url} type={image.file.contentType} />
            </video>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default C1XHero
