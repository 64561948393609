import React from 'react'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import person from './person.png'

const marks = [
  {
    value: 0,
    label: `4' 6"`
  },
  {
    value: 100,
    label: `7' 0"`
  }
]
const VerticalSlider = ({ sliderValue, setsliderValue }) => {
  const handleChange = evt => {
    setsliderValue(evt.target.value)
  }

  const heightConversion = () => {
    // 4'6" = 137
    // 7' = 213cm

    const inCentimeters = () => {
      const maxCM = 213
      const minCM = 137
      const centimeterRange = maxCM / minCM / 2.04
      return (sliderValue * centimeterRange + minCM).toFixed(0)
    }

    const inFeetAndInches = () => {
      const totalFeet = inCentimeters() * 0.03305
      const feet = Math.floor(totalFeet)
      const inches = Math.floor((totalFeet - feet) * 12)
      return `${feet}' ${inches}"`
    }

    return `${inFeetAndInches()}`
  }

  return (
    <Stack
      sx={{
        height: '67%',
        '.MuiSlider-root': { color: 'black' },
        '.MuiSlider-rail': { opacity: 1 },
        '.MuiSlider-mark': {
          width: '7px',
          height: '7px',
          borderRadius: '50%',
          backgroundColor: 'black'
        },
        '.MuiSlider-thumb': {
          backgroundImage: `url(${person})`,
          width: '32px',
          height: '34px',
          backgroundColor: 'transparent',
          borderRadius: 0,
          boxShadow: 'none !important'
        },
        '.MuiSlider-markLabel': { display: 'none' },
        '.MuiSlider-valueLabel': {
          top: '95%',
          right: '80%',
          fontSize: '18px',
          backgroundColor: 'transparent',
          color: 'black'
        }
      }}
      direction="row"
    >
      <Slider
        size="small"
        orientation="vertical"
        marks={marks}
        value={sliderValue}
        onChange={handleChange}
        valueLabelDisplay={'on'}
        getAriaLabel={() => 'Rider Height'}
        valueLabelFormat={heightConversion}
      />
    </Stack>
  )
}

export default VerticalSlider
