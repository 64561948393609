import React from 'react'
import silhouette from './silhouette.svg'

const ImageBox = ({
  sliderValue,
  wipeSliderValue,
  c1x,
  comparisonImage,
  comparisonImages
}) => {
  const heightFormula = () => {
    return 53 + sliderValue / 2.4
  }
  const comparisonBike = comparisonImage.gatsbyImageData?.images?.fallback?.src

  return (
    <div className="imageBox">
      <img
        src={silhouette}
        alt="human silhouette in black to compare to height of motorcycle"
        style={{ height: `${heightFormula()}%`, opacity: 0.15 }}
        className="silhouette"
      />
      <div className="bike">
        <img
          src={c1x}
          alt="Side view of the Super 73 C1X to compare height to human silhouette"
          style={{
            clipPath: `polygon(0 0, ${wipeSliderValue}% 0, ${wipeSliderValue}% 100%, 0% 100%)`
          }}
        />
        <img
          className={`comparison-bike ${comparisonImage.title}`}
          alt={comparisonImages[0].description ?? comparisonImages[0].title}
          style={{
            opacity:
              comparisonImage.title === comparisonImages[0].title ? 1 : 0,
            clipPath: `polygon(${wipeSliderValue}% 0, 100% 0%, 100% 100%, ${wipeSliderValue}% 100%)`
          }}
          src={comparisonImages[0].gatsbyImageData?.images?.fallback?.src}
        />
        <img
          className={`comparison-bike ${comparisonImage.title}`}
          alt={comparisonImages[1].description ?? comparisonImages[1].title}
          style={{
            opacity:
              comparisonImage.title === comparisonImages[1].title ? 1 : 0,
            clipPath: `polygon(${wipeSliderValue}% 0, 100% 0%, 100% 100%, ${wipeSliderValue}% 100%)`
          }}
          src={comparisonImages[1].gatsbyImageData?.images?.fallback?.src}
        />
      </div>
    </div>
  )
}

export default ImageBox
