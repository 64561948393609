/**@jsx jsx */
import React from 'react'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import bike from './bike.svg'
import { Select, Box, jsx } from 'theme-ui'
import Arrow from '~/assets/images/icons/downarrow.svg'

const marks = [
  {
    value: 0,
    label: `0`
  },
  {
    value: 100,
    label: `100`
  }
]

const HorizontalSlider = ({
  wipeSliderValue,
  setWipeSliderValue,
  setCurrentComparison,
  currentComparison,
  comparisonImages
}) => {
  const handleChange = evt => {
    setWipeSliderValue(evt.target.value)
  }

  return (
    <Stack
      sx={{
        position: 'absolute',
        bottom: ['-75px', null, null, '-35px'],
        left: ['45%', null, '55%', '50%'],
        transform: 'translateX(-50%)',
        width: ['80%', '80%', '60%', '33%'],
        '.MuiSlider-root': { color: 'black' },
        '.MuiSlider-rail': { opacity: 1 },
        '.MuiSlider-mark': {
          width: '7px',
          height: '7px',
          borderRadius: '50%',
          backgroundColor: 'black'
        },
        '.MuiSlider-thumb': {
          backgroundImage: `url(${bike})`,
          width: '32px',
          height: '18px',
          backgroundColor: 'transparent',
          borderRadius: 0,
          boxShadow: 'none !important'
        },
        '.MuiSlider-markLabel': { display: 'none' }
      }}
      direction="row"
    >
      <Box
        sx={{
          position: 'absolute',
          left: '-72px',
          bottom: '12px',
          transform: 'translateX(-100%)',
          padding: '8px 41px 8px 31px',
          fontFamily: 'Neue Haas GDP',
          fontWeight: '500',
          fontSize: ['1.6rem', '1.8rem'],
          letterSpacing: '0.04em',
          background: 'black',
          color: 'white',
          borderRadius: 0,
          display: ['none', null, null, 'block']
        }}
      >
        SUPER73-C1X
      </Box>
      <Slider
        size="small"
        marks={marks}
        value={wipeSliderValue}
        onChange={handleChange}
        valueLabelDisplay={'off'}
        getAriaLabel={() => 'Vehicle Comparison'}
      />
      <div
        className="comparison-selector"
        sx={{
          position: 'absolute',
          right: ['43%', null, null, '-72px'],
          bottom: ['-70px', null, null, '12px'],
          transform: ['translateX(50%)', null, null, 'translateX(100%)'],
          width: ['210px', null, null, 'auto']
        }}
      >
        <Select
          sx={{
            padding: '8px 41px 8px 31px',
            fontFamily: 'Neue Haas GDP',
            fontWeight: '500',
            fontSize: ['1.6rem', '1.8rem'],
            letterSpacing: '0.04em',
            background: 'black',
            color: 'white',
            borderRadius: 0
          }}
          aria-label="Choose Comparison"
          onChange={e => setCurrentComparison(e.target.value)}
          value={currentComparison}
          defaultValue={0}
        >
          {comparisonImages.map((image, i) => {
            return (
              <option
                style={{
                  fontFamily: 'Arial'
                }}
                value={i}
              >
                {image.title}
              </option>
            )
          })}
        </Select>
        <Arrow
          sx={{
            position: 'absolute',
            top: '50%',
            right: '16px',
            transform: 'translateY(-50%)'
          }}
        />
        <small
          sx={{
            position: 'absolute',
            bottom: 0,
            transform: 'translate(0, 100%)',
            width: '100%',
            lineHeight: '270%',
            letterSpacing: '0.1em',
            fontSize: '10px',
            textAlign: 'center',
            fontWeight: 'medium'
          }}
        >
          CHOOSE COMPARISON
        </small>
      </div>
    </Stack>
  )
}

export default HorizontalSlider
