/**@jsx jsx */
import React, { useState } from 'react'
import VerticalSlider from './VerticalSlider'
import './styles.css'
import ImageBox from './ImageBox'
import HorizontalSlider from './HorizontalSlider'
import { Select, Box, jsx } from 'theme-ui'

export default function HeightComponent({ comparisonImages, c1x, heading }) {
  const [sliderValue, setsliderValue] = useState(60)
  const [wipeSliderValue, setWipeSliderValue] = useState(60)
  const [currentComparison, setCurrentComparison] = useState(0)

  return (
    <div sx={{ overflow: 'hidden' }}>
      <h1
        sx={{
          textAlign: 'center',
          mt: ['30px', null, null, '71px'],
          mb: 0,

          fontWeight: 'medium',
          fontSize: ['3rem', null, '3.6rem']
        }}
      >
        {heading}
      </h1>
      <div className="container">
        <div className="slider" sx={{ position: 'relative' }}>
          <VerticalSlider
            sliderValue={sliderValue}
            setsliderValue={setsliderValue}
          />
          <small
            sx={{
              position: 'relative',
              transform: [null, null, null, 'translate(50%, 0)'],
              left: ['-60px', null, null, '-85%'],
              top: ['10px', null, null, '10px'],
              width: '100px',
              display: 'block',
              lineHeight: '1.4',
              letterSpacing: '0.1em',
              fontSize: '10px',
              textAlign: 'center',
              fontWeight: 'medium',
              whiteSpace: 'normal'
            }}
          >
            USE SLIDER TO SCALE RIDER HEIGHT
          </small>
        </div>
        <ImageBox
          wipeSliderValue={wipeSliderValue}
          sliderValue={sliderValue}
          comparisonImage={comparisonImages[currentComparison]}
          comparisonImages={comparisonImages}
          c1x={c1x}
        />

        <HorizontalSlider
          wipeSliderValue={wipeSliderValue}
          setWipeSliderValue={setWipeSliderValue}
          setCurrentComparison={setCurrentComparison}
          currentComparison={currentComparison}
          comparisonImages={comparisonImages}
        />
        <small
          sx={{
            position: 'absolute',
            bottom: ['-55px', null, '-50px', '-15px'],
            transform: 'translate(0, 100%)',
            width: '100%',
            lineHeight: '270%',
            letterSpacing: '0.1em',
            fontSize: '10px',
            textAlign: 'center',
            fontWeight: 'medium'
          }}
        >
          USE SLIDER TO SHOW VEHICLE COMPARISON
        </small>
      </div>
    </div>
  )
}
