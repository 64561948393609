/**@jsx jsx */
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Flex, Box, Heading, Text, jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichTextRenderer from '../Content/RichText'
import { Fragment } from 'react'
const ShowcaseCarousel = ({ section }) => {
  const { title, media: slides, copy } = section
  const slickSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: false,
    slidesToShow: 2,
    infinite: true,
    centerMode: true,
    centerPadding: '105px',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px'
        }
      }
    ]
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        pt: '71px',
        pb: '71px',
        mx: 'auto',
        overflow: 'hidden'
      }}
    >
      <Heading
        sx={{
          fontWeight: 'medium',
          fontSize: ['3rem', null, '3.6rem'],
          px: '10px',
          mb: '51px',
          textAlign: 'center'
        }}
      >
        {title}
      </Heading>
      <Box sx={{ position: 'relative', width: '100%' }}>
        {slides.length > 2 && (
          <Slider
            {...slickSettings}
            sx={{
              '.slick-slide': {
                padding: ['0 5px', null, null, null, '0 11px']
              },
              '.slick-list': {
                margin: ['0 -5px', null, null, null, '0 -11px']
              }
            }}
          >
            {slides.map(slide => {
              return (
                <Flex
                  key={slide.id}
                  sx={{
                    flexDirection: 'column'
                  }}
                >
                  <GatsbyImage
                    alt={slide.media.description ?? slide.media.title}
                    image={slide.media.gatsbyImageData}
                  />
                  <Text
                    sx={{
                      backgroundColor: 'black',
                      py: '25px',
                      px: ['30px', null, null, null, '70px'],
                      fontSize: [3, null, null, null, '1.8rem'],
                      color: 'white',
                      p: { m: 0 }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: slide.description?.childMarkdownRemark?.html
                    }}
                  />
                </Flex>
              )
            })}
          </Slider>
        )}
        {slides.length <= 2 && (
          <Fragment>
            <Flex sx={{ gap: '14px' }}>
              {slides.map(slide => {
                return (
                  <Flex
                    key={slide.id}
                    sx={{
                      flexDirection: 'column',
                      flex: 1
                    }}
                  >
                    <GatsbyImage
                      alt={slide.media.description ?? slide.media.title}
                      image={slide.media.gatsbyImageData}
                    />
                  </Flex>
                )
              })}
            </Flex>
            <Flex
              sx={{
                maxWidth: '1160px',
                mx: 'auto',
                px: '20px',
                letterSpacing: 0,
                mt: ['25px', null, null, '50px'],
                h2: {
                  fontSize: ['1.8rem', null, null, '2.4rem'],
                  fontWeight: 'medium',
                  borderLeft: '5px solid black',
                  lineHeight: '135%',
                  pl: '20px',
                  m: 0
                }
              }}
            >
              <RichTextRenderer richText={copy} />
            </Flex>
          </Fragment>
        )}
      </Box>
    </Flex>
  )
}
export default ShowcaseCarousel
