/**@jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { useEffect, useState } from 'react'
import { Flex, Box, Heading, Text, Button, jsx } from 'theme-ui'
import KeyFeature from './KeyFeature'
const KeyFeatures = ({ section }) => {
  const { title, media } = section
  const [activeFeature, setActiveFeature] = useState(0)
  const [hideImage, setHideImage] = useState(false)
  const [activeImage, setActiveImage] = useState(0)

  useEffect(() => {
    setHideImage(true)
    setTimeout(() => {
      setActiveImage(activeFeature)
      setHideImage(false)
    }, 250)
  }, [activeFeature])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        py: '71px',
        maxWidth: '1440px',
        mx: 'auto'
      }}
    >
      <Heading
        sx={{
          fontWeight: 'medium',
          fontSize: ['3rem', null, '3.6rem'],
          px: '10px',
          mb: '42px',
          textAlign: 'center'
        }}
      >
        {title}
      </Heading>
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          gap: ['15px', null, '40px']
        }}
      >
        <Box
          sx={{
            width: ['100%', null, null, '60%'],
            position: 'relative',
            pb: ['63%', '50%', null, '37%'],
            opacity: hideImage ? 0 : 1,
            transition: 'opacity 0.25s ease-in-out',
            '.gatsby-image-wrapper': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }
          }}
        >
          <GatsbyImage
            alt={
              media[activeImage]?.media?.description ??
              media[activeImage]?.media?.title
            }
            image={media[activeImage]?.media?.gatsbyImageData}
          />
        </Box>
        <Flex
          sx={{
            width: ['auto', null, null, '40%'],
            alignSelf: 'center',
            flexDirection: 'column',
            gap: ['20px', null, null, '46px'],
            px: [2, null, null, '0'],
            mt: ['20px', null, null, 0]
          }}
        >
          {media.map((feature, i) => {
            const { title, media: image, description } = feature
            const active = activeFeature === i
            return (
              <KeyFeature
                setHideImage={setHideImage}
                key={title + i}
                title={title}
                image={image}
                description={description}
                active={active}
                setActive={setActiveFeature}
                index={i}
              />
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}
export default KeyFeatures
