/**@jsx jsx */
import { Flex, Text, Box, jsx } from 'theme-ui'
import RichTextRenderer from '../Content/RichText'

const TimelineV1 = ({ timeline, activeInfo, setActiveInfo, showTC }) => {
  const { heading, subheading, media } = timeline
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        p: ['80px 20px', null, null, '142px 82px 135px 89px'],
        boxShadow: ['0px 4px 4px rgba(0, 0, 0, 0.25)', null, null, 'none'],
        width: '100%'
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: ['18px', null, null, '38px'],
          mb: ['60px', null, null, '100px'],
          lineHeight: 1.2,
          h1: {
            m: 0,
            fontSize: ['2.4rem', null, null, '36px'],
            fontWeight: 'medium',
            letterSpacing: '0.07em'
          },
          h2: {
            m: 0,
            fontSize: ['1.8rem', null, null, '2.4rem'],
            fontWeight: 'medium',
            borderLeft: '5px solid black',
            pl: '20px',
            ml: '20px',
            letterSpacing: 0
          },
          'p:empty': { display: 'none' }
        }}
      >
        <RichTextRenderer richText={heading} />
        <RichTextRenderer richText={subheading} />
      </Flex>
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          justifyContent: 'center',
          alignItems: ['center', null, null, 'stretch'],
          gap: ['80px', null, null, '16px'],
          width: '100%'
        }}
      >
        {media.map(
          (
            { title: heading, description: info, label: date, media: icon },
            i
          ) => {
            const active = date.includes('*')
            const color = active ? '#000000' : '#767676'
            const textColor = active ? '#FFFFFF' : '#767676'
            return (
              <Flex
                key={`timeline-${heading}`}
                sx={{
                  position: 'relative',
                  flexDirection: 'column',
                  height: '123px',
                  width: '100%',
                  flex: [null, null, null, 1],
                  justifyContent: 'space-between'
                }}
              >
                <Text
                  as="h3"
                  sx={{
                    fontSize: '1.6rem',
                    fontWeight: 'medium',
                    color: active ? 'black' : textColor,
                    lineHeight: '120%',
                    pl: [4, null, null, '20px'],
                    width: i === 2 ? '70%' : '100%'
                  }}
                >
                  {heading}
                </Text>
                <button
                  onClick={() => {
                    if (i === 0) {
                      if (typeof window !== 'undefined' && window.analytics) {
                        window.analytics.track('C1X CTA Clicked', {
                          label: 'c1x-cta-timeline'
                        })
                      }
                      showTC(true)
                    }
                    if (info && info.childMarkdownRemark?.html) setActiveInfo(i)
                  }}
                  sx={{
                    py: '8px',
                    backgroundColor: color,
                    color: '#FFFFFF',
                    textAlign: 'center',
                    border: 'none',
                    fontWeight: 'medium',
                    position: 'relative',
                    fontSize: '1.8rem',
                    fontFamily: 'monospace',
                    cursor: (info || i === 0) && 'pointer'
                  }}
                >
                  {date.replace('*', '')}
                  <Box
                    sx={{
                      height: '110px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '2px',
                      backgroundColor: color
                    }}
                  >
                    <Flex
                      sx={{
                        width: '13px',
                        height: '13px',
                        borderRadius: '50%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        left: '50%',
                        top: 0,
                        transform: 'translate(-50%, -100%)',
                        boxShadow: `0 0 0 2px ${color}`,
                        p: '3px'
                      }}
                    >
                      {active && (
                        <Box
                          sx={{
                            borderRadius: '50%',
                            width: '100%',
                            height: '100%',
                            backgroundColor: color
                          }}
                        />
                      )}
                    </Flex>
                  </Box>
                  {/* Line extruding to the left */}
                  {/* {i === 0 && (
                <Box
                  sx={{
                    width: '25vw',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transform: 'translateX(-100%)',
                    opacity: 0.15,
                    backgroundColor: color
                  }}
                />
              )} */}

                  {/* Line extruding to the right */}
                  {/* {i === media.length - 1 && (
                <Box
                  sx={{
                    width: '25vw',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    transform: 'translateX(100%)',
                    opacity: 0.15,
                    backgroundColor: color
                  }}
                />
              )} */}
                </button>
                {icon && icon.svg && (
                  <Flex
                    sx={{
                      position: 'absolute',
                      bottom: '-20px',
                      left: '50%',
                      transform: 'translate(-50%, 100%)',
                      width: '40px',
                      alignItems: 'center',
                      height: '40px',
                      svg: {
                        width: '100%',
                        height: 'auto',
                        path: {
                          fill: active ? '#000' : '#767676'
                        }
                      },
                      textAlign: 'center'
                    }}
                    dangerouslySetInnerHTML={{ __html: icon.svg.content }}
                  />
                )}
              </Flex>
            )
          }
        )}
      </Flex>
    </Flex>
  )
}

export default TimelineV1
