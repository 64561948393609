/**@jsx jsx */
import { useEffect, useRef, useState } from 'react'
import { Flex, Box, Text, jsx } from 'theme-ui'
const KeyFeature = ({
  title,
  image,
  description,
  active,
  setActive,
  index
}) => {
  const containerRef = useRef()
  const buttonRef = useRef()
  return (
    <Box
      sx={{
        height: active
          ? containerRef.current?.clientHeight + 'px'
          : buttonRef.current?.clientHeight + 'px',
        transition: 'height 0.25s ease, border-color 0.25s ease',
        pl: 3,
        borderRadius: 0,
        borderLeft: active ? '5px solid black' : '3px solid rgba(0, 0, 0, 0.5)'
      }}
    >
      <Flex
        ref={containerRef}
        sx={{
          flexDirection: 'column'
        }}
      >
        <button
          ref={buttonRef}
          onClick={() => setActive(index)}
          sx={{
            border: 'none',
            background: 'none',
            textAlign: 'left',
            cursor: 'pointer',
            fontFamily: 'body',
            color: 'black',
            fontSize: ['2rem', null, null, '2.4rem']
          }}
        >
          {title}
        </button>
        <Text
          sx={{
            opacity: active ? 1 : 0,
            visibility: active ? 'visible' : 'hidden',
            fontSize: ['1.6rem', null, null, '1.8rem'],
            p: { mb: 0, mt: '5px' }
          }}
          dangerouslySetInnerHTML={{
            __html: description?.childMarkdownRemark?.html
          }}
        />
      </Flex>
    </Box>
  )
}

export default KeyFeature
