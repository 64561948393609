/**@jsx jsx */
import { Button, jsx } from 'theme-ui'
const CTA = ({
  buttonText,
  buttonLink,
  buttonBackgroundColor,
  buttonTextColor,
  onClick,
  location
}) => {
  return (
    <Button
      onClick={() => {
        if (typeof window !== 'undefined' && window.analytics) {
          window.analytics.track('C1X CTA Clicked', { label: location })
        }
        onClick()
      }}
      sx={{
        backgroundColor: `${buttonBackgroundColor} !important`,
        color: `${buttonTextColor} !important`,
        fontSize: '1.5rem',
        width: '222px',
        height: '48px',
        fontWeight: 'medium',
        fontFamily: 'primary',
        border: 'none',
        boxShadow: ['0 0 25px rgba(0,0,0,0.2)', null, null, 'none']
      }}
    >
      {buttonText}
    </Button>
  )
}

export default CTA
