/**@jsx jsx */
import { Flex, Box, Text, IconButton, Heading, jsx } from 'theme-ui'
import React, { useEffect, useRef, useState } from 'react'
import Logo from '~/assets/images/logos/logo-small.svg'
import Checkbox from '~/assets/images/icons/checked.svg'
import Unchecked from '~/assets/images/icons/unchecked.svg'
import Close from '~/assets/images/icons/close-tc.svg'
import c1xBg from './images/c1x.png'
import { useOnScreen } from '~/hooks/utils/use-on-screen'
import {
  logSentryError,
  useAnalytics
} from '@chordcommerce/gatsby-theme-performance'
const TermsPopup = ({ close, link }) => {
  const [checked, setChecked] = useState(false)
  // const [email, setEmail] = useState('')
  const ref = useRef()
  const onScreen = useOnScreen(ref)
  // const { trackEmailCaptured, identify } = useAnalytics()

  const handleSubmit = e => {
    e.preventDefault()
    // const payload = {
    //   email,
    //   marketingConsent: checked,
    //   placement: {
    //     page: 'c1x',
    //     component: 'T&C Popup'
    //   }
    // }
    try {
      // await trackEmailCaptured(payload)
      if (typeof window !== 'undefined' && checked) {
        window.location = link
      }
    } catch (err) {
      logSentryError(err, { source: 'C1XSignup' })
    }
  }
  return (
    <Flex
      sx={{
        padding: ['24px 40px 28px 15px', '28px 140px 58px 32px'],
        borderRadius: '12px',
        maxWidth: ['95vw', '568px'],
        width: '100%',
        mx: 'auto',
        zIndex: 1,
        position: 'relative',
        background: `url(${c1xBg})`,
        backgroundPosition: ['70% 28%', null, 'unset'],
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <IconButton
        onClick={close}
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          cursor: 'pointer'
        }}
      >
        <Close
          sx={{
            filter: 'drop-shadow(0 0 4px rgba(0,0,0,0.5))'
          }}
        />
      </IconButton>
      <Flex sx={{ flexDirection: 'column' }}>
        <Logo
          sx={{
            path: { fill: 'black' },
            width: '170px',
            height: 'auto'
          }}
        />
        <Heading
          sx={{
            fontSize: '2.4rem',
            fontWeight: 'medium',
            fontFamily: 'monospace',
            my: 2
          }}
        >
          Terms and Conditions
        </Heading>
        <section
          sx={{
            height: ['220px', null, '422px'],
            overflow: 'auto',
            borderRadius: '4px',
            boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.3)',
            padding: ['20px', '24px'],
            background: 'white',
            fontSize: '1.4rem',
            color: 'rgba(0, 0, 0, 0.54)',
            letterSpacing: '0.02em',
            h3: { m: 0, textAlign: 'center' }
          }}
        >
          <p class="c1" sx={{ textAlign: 'center' }}>
            <span class="c9 c4">Super73-C1X</span>
          </p>
          <p class="c1" sx={{ textAlign: 'center' }}>
            <span class="c9 c4">Reservation Terms and Conditions</span>
          </p>
          <p class="c3">
            <span class="c9 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">IMPORTANT - PLEASE READ CAREFULLY</span>
            <span class="c2">
              : &nbsp;THESE TERMS AND CONDITIONS (THE &ldquo;TERMS&rdquo;)
              CONSTITUE A LEGALLY BINDING CONTRACT BETWEEN YOU AND SUPER73,
              INC., A DELAWARE CORPORATION (&ldquo;SUPER73&rdquo;,
              &ldquo;US,&rdquo; &ldquo;OUR&rdquo; OR &ldquo;WE&rdquo;), AND
              APPLIES TO YOUR RESERVATION FOR THE SUPER73-C1X (THE
              &ldquo;C1X&rdquo;). &nbsp;IF YOU DO NOT ACCEPT ANY OF THESE TERMS,
              PLEASE DO NOT PLACE A RESERVATION FOR THE C1X.
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              By reserving the C1X and paying the deposit of $73 (the
              &ldquo;Deposit&rdquo;), you hereby agree to these Terms in their
              entirety. &nbsp;By agreeing to these Terms, you
              (i)&nbsp;acknowledge and understand that you have reviewed and
              agreed to the Class Action Waiver detailed in these Terms, and
              (ii)&nbsp;confirm that you are at least 18 years of age, or
              otherwise have the consent of a parent or legal guardian to agree
              to the Terms.
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              WE RESERVE THE RIGHT TO MODIFY THESE TERMS AT ANY TIME, AND EACH
              SUCH MODIFICATION SHALL BE EFFECTIVE UPON POSTING ON OUR WEBSITE.
              &nbsp;ALL MATERIAL MODIFICATIONS WILL APPLY PROSPECTIVELY ONLY.
              &nbsp;IF ANY MODIFICATION IS NOT ACCEPTABLE TO YOU, YOUR SOLE
              REMEDY AND RECOURSE IS TO CANCEL THE RESERVATION AND RECEIVE A
              REFUND OF THE DEPOSIT.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">Reservation Process and Changes</span>
          </p>
          <p class="c0">
            <span class="c2">
              By reserving a C1X and providing the Deposit, you agree to provide
              full payment of the purchase price and take delivery of the C1X
              once Super73 notifies you that it has been built and is ready to
              be delivered to you. &nbsp;Your Deposit will place you in line for
              receiving the C1X at a time to be determined by Super73 in its
              sole discretion. &nbsp;The features, specifications, design and/or
              configuration of the C1X may change prior to production. &nbsp;You
              can cancel your reservation at any time prior to completing your
              purchase and seek a refund of the Deposit by providing a
              cancellation notice to Super73, and we will use reasonable efforts
              to refund the Deposit in a timely manner.
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              Once the C1X that you reserved has been built and is ready to be
              delivered, we will reach out to you by sending an email to the
              email address that you provided requesting payment of the purchase
              price. &nbsp;Once payment of the purchase price is received by
              Super73, the C1X will be delivered to you, subject to our standard
              terms and conditions of sale. &nbsp;If we do not receive your
              payment in full within a reasonable period of time, we may extend
              a purchase invitation to the next person on the reservations list.
              &nbsp;If you are unable to pay the full purchase price or if you
              otherwise cancel your reservation, you will be entitled to a full
              refund of your Deposit, but you will not be entitled to receive a
              C1X.
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              We will aim to serve you based on your reservation date, but we
              reserve the right to re-sequence reservations based on available
              production details at the time.{' '}
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              In summary, by providing your Deposit at the time of your
              reservation, you understand that Super73 may not have completed
              the development of the C1X, finalized the design of the C1X or
              begun manufacturing the C1X. &nbsp;We do not guarantee if or when
              the C1X will actually be manufactured or delivered. &nbsp;Your
              delivery date is dependent on many factors, including the
              C1X&rsquo;s con&#64257;guration and our manufacturing
              availability. &nbsp;You also understand that you will not receive
              the C1X unless and until you provide full payment of the purchase
              price.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">Payments</span>
          </p>
          <p class="c0">
            <span class="c2">
              Payments may be declined or returned to you, in Super73&rsquo;s
              sole discretion, for any reason or no reason at all. &nbsp;If we
              accept your Deposit for a C1X, your account, credit card, or other
              payment mechanism will be charged for the Deposit upon approval by
              Super73 regardless of when the product is expected to ship.
              &nbsp;If any credit card chargeback of any amount is issued on
              your reservation, your place in the queue for receiving the C1X
              will be paused until the chargeback has been resolved. &nbsp;Any
              applicable taxes (including sales tax) will be collected on
              reservations where such taxes are required to be collected.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">
              No Resale and Our Cancellation Rights. &nbsp;{' '}
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              Super73 and our a&#64259;liates and distributors sell Super73
              products directly to end-consumers, and we may unilaterally cancel
              any reservation that we believe has been made with a view toward
              resale of the C1X or that has otherwise been made in bad faith.
              &nbsp;We may also unilaterally cancel your reservation and refund
              your Deposit if we discontinue the C1X or any of its features or
              options after the time you place your reservation or if we
              determine that you are acting in bad faith.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">Warranty</span>
          </p>
          <p class="c0">
            <span class="c2">
              We offer limited warranties on our products. &nbsp;Unless
              otherwise communicated to you in writing by us, the C1X will be
              sold to you subject to our standard product warranty applicable to
              this product. &nbsp;EXCEPT FOR THE LIMITED WARRANTIES, THE C1X IS
              SOLD ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS-AVAILABLE&rdquo;
              BASIS WITHOUT ANY WARRANTIES OF ANY KIND. &nbsp;TO THE FULLEST
              EXTENT PERMITTED BY LAW, SUPER73 DISCLAIMS ALL WARRANTIES,
              INCLUDING, BUT NOT LIMITED TO, THE WARRANTY OF MERCHANTABILITY,
              NON-INFRINGEMENT OF PROPRIETARY OR THIRD-PARTY RIGHTS, AND THE
              WARRANTY OF FITNESS FOR ANY PARTICULAR PURPOSE.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c4 c9">Liability and Limitation of Remedies</span>
          </p>
          <p class="c0">
            <span class="c2">
              IN NO EVENT SHALL SUPER73 OR ANY OF ITS AFFILIATES, LICENSORS,
              DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS (COLLECTIVELY,
              &ldquo;AFFILIATES&rdquo;) BE LIABLE FOR ANY DIRECT, INDIRECT,
              INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES
              (EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) UNDER ANY
              CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY ARISING OUT
              OF OR RELATING IN ANY WAY TO THE SUBJECT MATTER OF THESE TERMS.
              &nbsp;THIS LIMITATION WILL APPLY REGARDLESS OF THE FAILURE OF THE
              ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              YOU AGREE AND UNDERSTAND THAT YOUR SOLE AND EXCLUSIVE REMEDY, AND
              SUPER73&rsquo;S SOLE AND EXCLUSIVE LIABILITY, FOR ANY AND ALL
              CLAIMS UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER
              THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE SUBJECT MATTER
              OF THESE TERMS WILL BE LIMITED TO THE REFUND OF YOUR DEPOSIT.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">
              Dispute Resolution and Class Action Waiver
            </span>
          </p>
          <p class="c0">
            <span class="c4">Governing Law</span>
            <span class="c2">
              . The parties acknowledge and agree that these Terms shall in all
              respects be interpreted, enforced and governed by the laws of the
              State of California applicable to instruments, persons and
              transactions which have legal contacts and relationships solely
              with the State of California and the laws of the United States of
              America. &nbsp;The parties exclude in its entirety the application
              to the order of the United Nations Convention on Contracts for the
              International Sale of goods. &nbsp;
            </span>
          </p>
          <p class="c0">
            <span class="c4">Choice of Forum.</span>
            <span class="c2">
              &nbsp;Each party irrevocably and unconditionally agrees that it
              shall not commence any action, litigation or proceeding of any
              kind whatsoever against the other party in any way arising from or
              relating to these Terms, including all exhibits, schedules,
              attachments and appendices attached hereto and thereto, and all
              contemplated transactions, including contract, equity, tort, fraud
              and statutory claims, in any forum other than United States
              District Court for the Central District of California or, if such
              court does not have subject matter jurisdiction, the courts of the
              State of California sitting in Orange County, and any appellate
              court from any thereof. &nbsp;Each party irrevocably and
              unconditionally submits to the exclusive jurisdiction of such
              courts and agrees to bring any such action, litigation or
              proceeding only in United States District Court for the Central
              District of California or, if such court does not have subject
              matter jurisdiction, the courts of the State of California sitting
              in Orange County. &nbsp;Each party agrees that a final judgment in
              any such action, litigation or proceeding is conclusive and may be
              enforced in other jurisdictions by any manner provided by
              applicable laws. &nbsp;
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              THE PARTIES WAIVE THEIR RIGHT TO A TRIAL BY JURY IN CONNECTION
              WITH ANY ACTIVITY ARISING UNDER OR RELATED TO THESE TERMS OR THE
              RESERVATION.
            </span>
          </p>
          <p class="c0">
            <span class="c4">
              Prohibition of Class and Representative Actions and
              Non-Individualized Relief.
            </span>
            <span class="c2">
              &nbsp; YOU AND SUPER73 AGREE THAT EACH OF US MAY BRING CLAIMS
              AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              ACTION OR PROCEEDING. UNLESS BOTH YOU AND SUPER73 AGREE OTHERWISE,
              THE COURT MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON&rsquo;S
              OR PARTY&rsquo;S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY
              FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO,
              THE COURT MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
              DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
              RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF
              NECESSITATED BY THAT PARTY&rsquo;S INDIVIDUAL CLAIM(S), EXCEPT
              THAT YOU MAY PURSUE A CLAIM FOR AND THE COURT MAY AWARD PUBLIC
              INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR
              THE ENFORCEABILITY OF THIS PROVISION.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">Notices and Communications</span>
          </p>
          <p class="c0">
            <span class="c10 c12">
              Super73 will provide any notices (including legal process) that it
              may be required to give you by any lawful method, including by
              making notices available through our website or by sending notices
              to any email address that you provide to Super73. Super73 will
              also
            </span>
            <span class="c2 c14">
              &nbsp;communicate with you about your reservation and send you
              updates, surveys and other information about Super73 products via
              the email address that you provide. If you&rsquo;d prefer not to
              receive certain emails from Super73 click&nbsp;the unsubscribe
              link in an email and unsubscribe.
            </span>
          </p>
          <p class="c0">
            <span class="c5">Please note that i</span>
            <span class="c10 c12">
              f you do not (i) provide Super73 with current and accurate contact
              information and/or (ii) subsequently provide any updated contact
              information to Super73, we may not be able to contact you about
              your reservation.
            </span>
          </p>
          <p class="c0">
            <span class="c10">
              You agree to send all notices under these Terms to Super73 by
              emailing them to
            </span>{' '}
            <span class="c11 c10">
              <a class="c8" href="mailto:customercare@super73.com">
                customercare@super73.com
              </a>
            </span>
            <span class="c2">.</span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">Your Privacy</span>
          </p>
          <p class="c0 c7">
            <span class="c2">
              At Super73, we respect the privacy of our customers. For details
              please see our Privacy Policy. By using this website and/or
              reserving a C1X, you consent to our collection and use of your
              personal data as outlined therein.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c9 c4">Miscellaneous</span>
          </p>
          <p class="c0">
            <span class="c4">Assignment.</span>
            <span class="c2">
              &nbsp; Nothing in these Terms may be assigned by you without the
              prior written consent of Super73. These Terms shall bind and inure
              to the benefit of Super73&rsquo;s assigns and successors,
              including, without limitation, any entity with which or into which
              Super73 shall merge or consolidate.
            </span>
          </p>
          <p class="c0">
            <span class="c4">Severability.</span>
            <span class="c2">
              &nbsp; In the event one or more of the terms of this Agreement are
              adjudicated invalid, illegal, or unenforceable, the adjudicating
              body may either interpret these Terms as if such terms had not
              been included, or may reform such terms to the limited extent
              necessary to make them valid, legal or enforceable, consistent
              with the economic and legal incentives underlying the Terms.
            </span>
          </p>
          <p class="c0">
            <span class="c4">Excuse of Super73&rsquo;s Performance.</span>
            <span class="c2">
              &nbsp; Super73&rsquo;s performance hereunder shall be excused if
              rendered impossible or &ldquo;impractical.&rdquo;
              &nbsp;&ldquo;Impractical&rdquo; shall have the same general
              meaning as in Section 2615 of the California Commercial Code.
            </span>
          </p>
          <p class="c0">
            <span class="c4">Waiver.</span>
            <span class="c2">
              &nbsp; Super73&rsquo;s failure to enforce at any time any of these
              Terms, to exercise any election or option provided herein, or to
              require at any time performance by you of any of the provisions
              hereof, shall in no way be construed to be a waiver of such
              provisions, nor in any way to affect the validity of the
              reservation or any part thereof, or the right of Super73
              thereafter to enforce each and every such provision. &nbsp;{' '}
            </span>
          </p>
          <p class="c0">
            <span class="c4">Questions? Concerns? Suggestions?</span>
            <span class="c10">&nbsp;Please contact us at</span>{' '}
            <span class="c10 c11">
              <a class="c8" href="mailto:customercare@super73.com">
                customercare@super73.com
              </a>
            </span>
            <span class="c2">
              &nbsp;to report any violations of these Terms or if you have any
              questions regarding these Terms.
            </span>
          </p>
          <p class="c3">
            <span class="c2"></span>
          </p>
          <div>
            <p class="c3">
              <span class="c6"></span>
            </p>
            <p class="c3">
              <span class="c6"></span>
            </p>
          </div>
          <p ref={ref} className="end" sx={{ mb: '50px' }}></p>
        </section>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .c6 {
              color: #000000;
              font-weight: 400;
              text-decoration: none;
              vertical-align: baseline;
              font-style: normal
          }
      
          .c2 {
              color: #000000;
              font-weight: 400;
              text-decoration: none;
              vertical-align: baseline;
              font-style: normal
          }
      
          .c3 {
              padding-top: 0pt;
              padding-bottom: 0pt;
              line-height: 1.5;
              orphans: 2;
              widows: 2;
              text-align: left;
              height: 11.5pt
          }
      
          .c1 {
              padding-top: 0pt;
              padding-bottom: 0pt;
              line-height: 1.5;
              orphans: 2;
              widows: 2;
              text-align: center
          }
      
          .c0 {
              padding-top: 0pt;
              padding-bottom: 12pt;
              line-height: 1.5;
              orphans: 2;
              widows: 2;
              text-align: left
          }
      
          .c9 {
              text-decoration: none;
              vertical-align: baseline;
              font-style: normal
          }
      
          .c11 {
              text-decoration-skip-ink: none;
              -webkit-text-decoration-skip: none;
              color: #0000ff;
              text-decoration: underline
          }
      
          .c13 {
              padding: 50.4pt 50.4pt 50.4pt 50.4pt
          }
      
      
      
          .c4 {
              font-weight: 700;
              font-size: 16px;
          }
      
          p {
              margin: 0;
              color: #000000;
          }`
          }}
        />
        <form onSubmit={handleSubmit}>
          {/* <label
            htmlFor="email"
            sx={{
              position: 'relative',
              span: {
                color: '#27B6FF',
                fontSize: '1.2rem',
                position: 'absolute',
                left: '16px',
                top: '-14px'
              },
              input: {
                height: '52px',
                width: '100%',
                borderRadius: '4px',
                px: 3,
                color: 'rgb(152, 152, 152)',
                pt: '10px',
                border: '1px solid black',
                fontSize: '1.6rem',
                ':placholder': {
                  opacity: 1
                }
              }
            }}
          >
            <span>email</span>
            <input
              type="email"
              required
              placeholder="name@mail.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </label> */}
          {/* <label
            htmlFor="marketing"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              gap: '11px',
              lineHeight: 1.2,
              my: '14px',
              fontSize: '1.4rem',
              letterSpacing: '0.01em'
            }}
          >
            <div
              sx={{
                width: '18px',
                height: '18px',
                position: 'relative',
                top: '0px'
              }}
            >
              {checked ? <Checkbox /> : <Unchecked />}
            </div>
            Keep me up to date on exclusive offers via email or SMS
            <input
              type="checkbox"
              id="marketing"
              name="marketing"
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
              sx={{ display: 'none' }}
            />
          </label> */}
          <label
            htmlFor="age-gate"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              gap: '11px',
              lineHeight: 1.2,
              my: '14px',
              fontSize: '1.4rem',
              letterSpacing: '0.01em'
            }}
          >
            <div
              sx={{
                width: '18px',
                height: '18px',
                position: 'relative',
                top: '1px'
              }}
            >
              {checked ? <Checkbox /> : <Unchecked />}
            </div>
            I certify that I am at least 18 years of age and a resident of the
            United States, and confirm that I have read and agree to the
            Reservation Terms and Conditions.
            <input
              type="checkbox"
              id="age-gate"
              name="age-gate"
              required
              checked={checked}
              onChange={e => {
                const isChecked = e.target.checked
                setChecked(isChecked)
                if (typeof window !== 'undefined' && window.analytics) {
                  if (isChecked) {
                    window.analytics.track('C1X Terms and Conditions Checked')
                  } else {
                    window.analytics.track('C1X Terms and Conditions Unchecked')
                  }
                }
              }}
              sx={{ opacity: 0 }}
            />
          </label>
          <button
            disabled={!(onScreen && checked)}
            type="submit"
            sx={{
              padding: '12px 0',
              border: 'none',
              backgroundColor: 'black',
              color: 'white',
              width: '100%',
              fontSize: '1.8rem',
              cursor: onScreen && checked ? 'pointer' : 'not-allowed',
              transition: 'background-color 0.25s ease',
              ':disabled': {
                backgroundColor: 'grey'
              }
            }}
          >
            Accept
          </button>
        </form>
      </Flex>
    </Flex>
  )
}

export default TermsPopup
