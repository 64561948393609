/**@jsx jsx */
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Flex, Heading, Box, jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import ImageCarousel from '../Generic/ImageCarousel'
const StudioShots = ({ section }) => {
  const { title, images } = section
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mt: '71px',
        mb: ['36px', null, '71px'],
        maxWidth: '1440px',
        mx: 'auto'
      }}
    >
      <Heading
        sx={{
          fontWeight: 'medium',
          fontSize: ['3rem', null, '3.6rem'],
          px: '10px',
          mb: '42px',
          textAlign: 'center'
        }}
      >
        {title}
      </Heading>
      <Box
        sx={{
          mx: 'auto',
          overflow: 'visible',
          '.dot, .dot.selected': { backgroundColor: '#000000 !important' },
          '.dot.selected': { height: '8px !important' },
          '.next-arrow, .prev-arrow': { display: ['none', 'flex'] },
          '.gatsby-image-wrapper': {
            width: '80%'
          },
          '.control-dots': {
            top: 'calc(100% + 3.8rem) !important'
          },
          '.slider': { top: 0 }
        }}
      >
        <ImageCarousel
          primaryImage={images[0]}
          secondaryImages={images.length > 1 ? images.slice(1) : []}
          sliderHeight={['25rem', '40rem', '52rem', null, '70rem']}
        />
      </Box>
    </Flex>
  )
}

export default StudioShots
