/**@jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'

const Modal = ({ closeModal, children }) => {
  return (
    <Flex
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        onClick={() => closeModal()}
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          animation: 'fadeInBackground 0.25s ease',
          cursor: 'pointer'
        }}
      ></Box>
      {children}
    </Flex>
  )
}

export default Modal
